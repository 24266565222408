import aclActions from '@enums/aclActions'
import aclFields from '@enums/aclFields'
import aclObjects from '@enums/aclObjects'
import adsTxt from '@enums/adsTxt'
import analytics from '@enums/analytics'
import analyticsMetrics from '@enums/analyticsMetrics'
import apiTokenStatus from '@enums/apiTokenStatus'
import bidderStatus from '@enums/bidderStatus'
import brandLogos from '@enums/brandLogos'
import browsers from '@enums/browsers'
import config from '@enums/config'
import configStatus from '@enums/configStatus'
import contractParameters from '@enums/contractParameters'
import cornerTemplate from '@enums/cornerTemplate'
import devices from '@enums/devices'
import devicesIcons from '@enums/devicesIcons'
import endpoints from '@enums/endpoints'
import feedTemplate from '@enums/feedTemplate'
import hierarchy from '@enums/hierarchy'
import icons from '@enums/icons'
import organizationTypes from '@enums/organizationTypes'
import overview from '@enums/overview'
import placeholder from '@enums/placeholder'
import portals from '@enums/portals'
import scss from '@enums/scss'
import slotTemplates from '@enums/slotTemplates'
import statusCode from '@enums/statusCode'
import table from '@enums/table'
import utm from '@enums/utm'
import views from '@enums/views'
import websiteProducts from '@enums/websiteProducts'

export default Object.freeze({
  aclActions,
  aclFields,
  aclObjects,
  adsTxt,
  analytics,
  analyticsMetrics,
  apiTokenStatus,
  bidderStatus,
  brandLogos,
  browsers,
  config,
  configStatus,
  contractParameters,
  cornerTemplate,
  devices,
  devicesIcons,
  endpoints,
  feedTemplate,
  hierarchy,
  icons,
  organizationTypes,
  overview,
  placeholder,
  portals,
  scss,
  slotTemplates,
  statusCode,
  table,
  utm,
  views,
  websiteProducts
})
